import React from 'react';
import '../css/footer.css';

export default () => (
  <footer className="footer">
    <div className="wrapper">
      <p>
        Made with {''}
        <a
          href="https://reactjs.org/"
          target="_blank"
          rel="noopener noreferrer"
          alt="react"
        >
          React
        </a>
        {''} and {''}
        <a
          href="https://inkscape.org"
          target="_blank"
          rel="noopener noreferrer"
          alt="inkscape"
        >
          Inkscape
        </a>
      </p>
      <p>
        The code is open source and you can find it on{' '}
        <a
          href="https://gitlab.com/radish-creative-lab"
          target="_blank"
          rel="noopener noreferrer"
          alt="gitlab"
        >
          Gitlab
        </a>
      </p>
    </div>
  </footer>
);
