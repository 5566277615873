import React from 'react';
import Svg from '../images/landing.svg';
import '../css/landing.css';

export default () => {
  return (
    <div className="landing">
      <div className="wrapper">
        <h1>Radish Creative Lab</h1>
        <h3>We deliver cutting-edge experiences</h3>
      </div>
      <Svg />
    </div>
  );
};
