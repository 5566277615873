import React from 'react';
import Head from '../components/head.js';
import Main from '../layouts/main.js';
import Landing from '../components/landing.js';
import About from '../components/about.js';
import Services from '../components/services.js';
import Contact from '../components/contact.js';
import Separator from '../components/separator.js';

export default () => {
  return (
    <React.Fragment>
      <Head title="Radish Creative Lab" />
      <Main>
        <Landing />
        <About />
        <Services />
        <Separator />
        <Contact />
      </Main>
    </React.Fragment>
  );
};
