import React from 'react';
import '../css/services.css';

export default () => {
  return (
    <div className="services">
      <div className="wrapper">
        <h1>Services</h1>
        <p>
          We specialize in the emerging potential of the web platform. We use
          our deep expertise with canvas, peer-to-peer, and modular software
          architecture to deliver cutting-edge experiences.
        </p>
        <div className="grid">
          <div className="box">
            <div>
              <h3>Web Development</h3>
              <p>
                We create efficient applications that work as websites or mobile
                apps.
              </p>
              <p>We also make low-power & offline centered websites.</p>
            </div>
          </div>
          <div className="box">
            <div>
              <h3>Design/UX</h3>
              <p>
                We design with the user at the centre of our focus. We want our
                apps and websites to produce enjoyable, fast and intuitive
                experiences through a minimalistic and crystal-clear
                navigational narrative.
              </p>
            </div>
          </div>
          <div className="box">
            <div>
              <h3>Consultancy</h3>
              <p>
                We provide recommendations to reduce risks and improve
                processes. Make better decisions on your next project. Let us
                share our knowledge and expertise.
              </p>
            </div>
          </div>
          <div className="box">
            <div>
              <h3>Generative Design</h3>
              <p>
                We bring to life ever changing digital sculptures, immersive
                installations and data-driven designs.
              </p>
              <p>
                Blending design, software and aesthetics emerging from data,
                processes and human behaviour.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
