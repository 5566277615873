import React from 'react';
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import '../css/main.css';

export default ({ children }) => (
  <div>
    <Header />
    {children}
    <Footer />
  </div>
);
