import React from 'react';
import '../css/about.css';

export default () => {
  return (
    <div className="about">
      <div className="wrapper">
        <h1>About</h1>
        <p>
          We are engineers and artists with years of experience, driven to
          explore the unknown, expand our craft, and add beauty and meaning to
          the world.
        </p>
        <p>
          We simply make things. And help others make things. Because, at our
          very core, we are collaborators.
        </p>
        <p>
          We give talks, facilitate workshops, publish free and open source
          software, and participate in open collaboration with the greater
          community.
        </p>
        <p>We are always sharing what we know.</p>
      </div>
    </div>
  );
};
