import React from 'react';
import Helmet from 'react-helmet';

export default ({ title }) => {
  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
    </React.Fragment>
  );
};
