import React from 'react';
import '../css/contact.css';

export default () => {
  return (
    <div className="contact">
      <div className="wrapper">
        <h1>Contact</h1>
        <div className="call-phone">
          <strong>Wire: </strong>@radishcreative
        </div>
        <div className="call-email">
          <strong>Email: </strong>
          <a href="mailto:radish-creative@protonmail.com">
            radish-creative@protonmail.com
          </a>
        </div>
        <h3>Business Hours (GMT)</h3>
        <table className="table table-sm">
          <tbody>
            <tr>
              <td className="day">Monday</td>
              <td>9:30am</td>
              <td>-</td>
              <td>5:00pm</td>
            </tr>
            <tr>
              <td className="day">Tuesday</td>
              <td>9:30am</td>
              <td>-</td>
              <td>5:00pm</td>
            </tr>
            <tr>
              <td className="day">Wednesday</td>
              <td>Closed</td>
              <td />
              <td />
            </tr>
            <tr>
              <td className="day">Thursday</td>
              <td>9:30am</td>
              <td>-</td>
              <td>5:00pm</td>
            </tr>
            <tr>
              <td className="day">Friday</td>
              <td>7:00am</td>
              <td>-</td>
              <td>3:00pm</td>
            </tr>
            <tr>
              <td className="day">Saturday</td>
              <td>Closed</td>
              <td />
              <td />
            </tr>
            <tr>
              <td className="day">Sunday</td>
              <td>Closed</td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
