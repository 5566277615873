import React from 'react';
import '../css/separator.css';

export default () => {
  return (
    <div className="separator">
      <svg viewBox="0 0 1440 120">
        <path d="M 1441.1465,28.089172 1440,120 H 0 V 21.210191 c 120,13.859873 240,26.522293 360,26.522293 120,0 240,-12.66242 360,-26.522293 C 808.32779,12.416393 874.57363,6.8770203 918.73753,4.5920731 972.49168,1.8109458 1026.2458,0.42038217 1080,0.42038217 c 120,0 241.1465,13.80891683 361.1465,27.66878983 z" />
      </svg>
    </div>
  );
};
