import React from 'react';
import Logo from './logo.js';
import '../css/header.css';

export default () => {
  return (
    <header className="header">
      <div className="logo">
        <Logo />
      </div>
    </header>
  );
};
